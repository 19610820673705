import { render, staticRenderFns } from "./color-picker.vue?vue&type=template&id=9c71d40a&scoped=true&"
import script from "./color-picker.vue?vue&type=script&lang=ts&"
export * from "./color-picker.vue?vue&type=script&lang=ts&"
import style0 from "./color-picker.vue?vue&type=style&index=0&id=9c71d40a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c71d40a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\123\\river-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9c71d40a')) {
      api.createRecord('9c71d40a', component.options)
    } else {
      api.reload('9c71d40a', component.options)
    }
    module.hot.accept("./color-picker.vue?vue&type=template&id=9c71d40a&scoped=true&", function () {
      api.rerender('9c71d40a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form-design/config/elements/color-picker.vue"
export default component.exports